import axios from "axios";
import { ErrorToast } from "../reactComponents/utils/utilities";

// LocalStorageServices
const axiosClient = axios.create();

//Add a request interceptor
axiosClient.interceptors.request.use(
  (config) => {
    if (localStorage.accessToken) {
      config.headers.Authorization = `Bearer ${localStorage.accessToken}`;
      // config.headers.cdomain = "https://aggregator.dev.softairlines.com";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//Add a response interceptor
axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    console.log("check your token details", error);
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = "/";
    } else {
      // ErrorToast(error)
      console.log('error', error);
    }
    return Promise.reject(error);
  }
);

export default axiosClient;
