import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";


/**  ********************************************
            Function to format currency
********************************************  */
export const numberFormatter = (number) => {
  let currency = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    symbol: "false",
  }).format(number);

  return currency.substring(1);
};


export const currencyAndNumberFormat = (number, currencyCode) => {
  // if (currencyCode === "KWD") {
  //   let globalConstants = JSON.parse(localStorage.globalConstants);

  //   const currencyList = globalConstants.currencies;
  //   let activeCurrency = currencyList.filter(
  //     (curr) => curr.currencyCode === currencyCode
  //   );

  //   let a = activeCurrency[0].symbol + " " + currencyFormatter(number);
  //   return a;
  // } else {
    let numbFormat = currencyCode ? (currencyCode === "INR" ? "en-IN" : "en-US") : "en-IN";

    // Check if the number is zero and return a formatted string for zero
    if (number === '' || number === undefined) {
      let currencyType = new Intl.NumberFormat(numbFormat, {
        style: "currency",
        currency: currencyCode ? currencyCode : "INR",
        currencyDisplay: "symbol",
      }).format(0)
      return currencyType.substring(0, 1) + " " + currencyType.substring(1);
    }

    var currency = new Intl.NumberFormat(numbFormat, {
      style: "currency",
      currency: currencyCode ? currencyCode : "INR",
      currencyDisplay: "symbol",
    }).format(number);
    
    // Return the formatted currency string with a space after the currency symbol
    return currency.substring(0, 1) + " " + currency.substring(1);
  // }
};

// return currency symbol
export const currencySymbol = (currencyCode) => {
  let globalConstants = JSON.parse(localStorage.globalConstants);

    const currencyList = globalConstants.currencies;
    let activeCurrency = currencyList.filter(
      (curr) => curr.currencyCode === currencyCode
    );

    return activeCurrency[0].symbol;
};

// to return currency code
export const currencyCode = (symbol) => {
  let globalConstants = JSON.parse(localStorage?.globalConstants);

  const currencyList = globalConstants?.currencies;
  let activeCurrency = currencyList.find(
      (curr) => curr?.symbol === symbol
  );

  return activeCurrency ? activeCurrency?.currencyCode : null;
};


/**  ********************************************
            Function to format currency
  ********************************************  */
export const currencyFormatter = (number) => {
  var currency = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    symbol: "false",
  }).format(number);
  return currency.substring(1);
};

export const ExtractHours = (blockTime) => {
  // Split the blockTime string using ":" as the separator
  const timeParts = blockTime?.split(":");

  // Extract the hours (the first part of the split)
  if (timeParts) {
    const hours = parseInt(timeParts[0], 10);
    return hours;
  } else return 0;
};

export const ExtractMinutes = (timeString) => {
  const timeParts = timeString?.split(":");
  // return timeParts.length === 2 ? timeParts[1] : "";
  if (timeParts) {
    const hours = parseInt(timeParts[1]);
    return hours;
  } else return 0;
};


export const phoneFormat = (input) => {
  if (!input || isNaN(input)) return `${input}`;
  if (typeof input !== "string") input = input.toString();
  if (input.length === 10) {
    return input.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  } else if (input.length < 10) {
    //   return 'was not supplied enough numbers please pass a 10 digit number'
    return input;
  } else if (input.length > 10) {
    //   return 'was supplied too many numbers please pass a 10 digit number'
    return input;
  } else {
    //   return 'something went wrong'
    return input;
  }
};

export const preventMinus = (e) => {
  if (
    e.code === "Minus" ||
    e.code === "NumpadSubtract" ||
    e.code === "NumpadAdd" ||
    e.code === "NumpadDivide" ||
    e.code === "NumpadMultiply" ||
    e.code === "NumpadDecimal" ||
    e.code === "Period"
  ) {
    e.preventDefault();
  }
};

export const preventMinusNew = (e) => {
  if (
    e.code === "Minus" ||
    e.code === "NumpadSubtract" ||
    e.code === "NumpadAdd" ||
    e.code === "NumpadDivide" ||
    e.code === "NumpadMultiply" ||
    e.code === "NumpadDecimal"
  ) {
    e.preventDefault();
  }
};

export const reminderMsgTypeListArr = ["Email", "SMS", "WhatsApp"];

export const aircraftServicesListArr = [
  "Adult beverages",
  "Breakfast",
  "Free Music",
  "Headphones",
  "Magazines",
  "Medication",
  "Motion sickness bag",
  "Pillows",
  "Snacks",
  "Soda or water",
  "Wifi",
];

export const showErrorToast = (res) =>
  toast.error(`${res.message}`, {
    className:
      " alert alert-dismissible bg-primary d-flex flex-column flex-sm-row p-5 mb-10",
    toastId: "123",
  });

export const showSuccessMessage = (res) =>
  toast.success(`${res}`, {
    className: "Toastify__toast--success Toastify__toast--success::after",
    toastId: "122",
  });

export const ErrorToast = (res) =>
  toast.error(`${res}`, {
    className:
      " alert alert-dismissible bg-primary d-flex flex-column flex-sm-row p-5 mb-10",
    toastId: "124",
  });

export const showSuccessToast = (res) =>
  toast.success(`${res.message}`, {
    className: "Toastify__toast--success Toastify__toast--success::after",
    toastId: "123",
  });

export const randomNumGenerator = () => {
  const characters = "0123456789";
  let genFileKeytem = "";
  for (var i = 0; i < 13; i++) {
    genFileKeytem += characters.charAt(
      Math.floor(Math.random() * characters.length)
    );
  }
  return genFileKeytem;
};
export const copyToClipBoard = (id) => {
  var copyText = document.getElementById(id);
  // console.log(copyText.innerText,'kjfdk')
  // Select the text field
  // copyText.select();
  // copyText.setSelectionRange(0, 99999); // For mobile devices

  // Copy the text inside the text field
  navigator.clipboard.writeText(copyText.innerText);
  showSuccessMessage("Copied");
};

/**  ********************************************
            Function to copy text
           ********************************************  */
export const copyToClipBoardLocation = (text) => {
  navigator.clipboard.writeText(text);
  showSuccessMessage("Copied");
};

export const MinimumChargeableHoursListArr = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

export const enduranceHoursListArr = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];

export const MinimumChargeableMinutesListArr = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
];

export const aircraftSeatAvailabilityArr = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
];

export const helicopterSeatAvailabilityArr = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
];

// disable past dates
const yesterday = moment().subtract(1, "day");
export const currentToFutureDates = (current) => {
  return current.isAfter(yesterday);
};

// disable future dates
const today = moment();
export const disableFutureDates = (current) => {
  return current.isBefore(today);
};

export const staffDesignations = [
  "Accountable Manager",
  "Asst. Ops Manager",
  "Executive - Accounts",
  "Executive Ops",
  "Manager - Accounts",
  "Operations Manager",
  "Sr. Executive Ops",
];

export const userPageAccess = [
  "Crew Allotment",
  "Fleet Onboarding",
  "Flight Activations",
  "Passenger Activity",
  "Payment Followups",
  "Send Quotations",
  "Trip Sheet Preparation",
];

/**  ********************************************
    Converts UTC to Local Time  
 ********************************************  */
export const UTCDateAndTime = (datetime) => {
  
  const date = new Date(datetime);
  
  const utcDate = new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  );
  
  // if(utcDate === "Invalid Date") {
    return utcDate?.toISOString();
  // }
};

export const LoadingFallback = () => (
  <div className="d-flex justify-content-center py-5">
    <div className="spinner-border" role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
);

// Function to round time to nearest 5 minutes
export const roundTimeToNearest5Minutes = (time) => {
  const minutes = moment(time).minutes();
  const roundedMinutes = Math.ceil(minutes / 5) * 5;
  return moment(time).minutes(roundedMinutes).seconds(0).toDate();
};

// to download documents
export const handleDocumentsDownload = async (url, type, name) => {
  try {
    const response = await axios({
      url: url,
      method: "GET",
      responseType: "blob", // Important: responseType must be set to 'blob' to download files
    });

    let fileType = "";
    
    // Extract the fileType from url 
    const fileExtension = url.split('?')[0].split('.').pop();

    fileType = fileExtension === "pdf" ? "pdf" : `${fileExtension}`
    // Create a temporary anchor element and initiate the file download
    const downloadUrl = window.URL.createObjectURL(new Blob([response.data]), { type: fileType });
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute(
      "download",`${name.toUpperCase()}_${type}.${fileType}`
    ); // Set the desired file name and extension
    document.body.appendChild(link);
    link.click();

    // Clean up the temporary anchor element
    link.parentNode.removeChild(link);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

// transform long lease status
export const transformData = (data) => {
  return data.map((item) => ({
    monthCode: item.monthCode,
    enquiries: item.enquiries.map((enquiry) => ({
      enquiryStatus: enquiry["leaseEnquiryStatus"],
      count: enquiry.count,
    })),
    quotes: item.quotes.map((quote) => ({
      quoteStatus: quote["leaseQuoteStatus"],
      count: quote.count,
    })),
  }));
};

// merge long lease and normal bookings
export const mergeData = (data1, data2) => {
  const mergedData = {};

  // Initialize mergedData with the first dataset
  data1.forEach((item) => {
    mergedData[item.monthCode] = {
      monthCode: item.monthCode,
      enquiries: [...item.enquiries],
      quotes: [...item.quotes],
    };
  });

  // Merge the second dataset into mergedData
  data2.forEach((item) => {
    if (!mergedData[item.monthCode]) {
      mergedData[item.monthCode] = {
        monthCode: item.monthCode,
        enquiries: [],
        quotes: [],
      };
    }

    // Merge enquiries
    item.enquiries.forEach((enquiry) => {
      const existingEnquiry = mergedData[item.monthCode].enquiries.find(
        (e) => e.enquiryStatus === enquiry.enquiryStatus
      );
      if (existingEnquiry) {
        existingEnquiry.count += enquiry.count;
      } else {
        mergedData[item.monthCode].enquiries.push({ ...enquiry });
      }
    });

    // Merge quotes
    item.quotes.forEach((quote) => {
      const existingQuote = mergedData[item.monthCode].quotes.find(
        (q) => q.quoteStatus === quote.quoteStatus
      );
      if (existingQuote) {
        existingQuote.count += quote.count;
      } else {
        mergedData[item.monthCode].quotes.push({ ...quote });
      }
    });
  });

  return data1.map((item) => mergedData[item.monthCode]);
};

// to have bookings count
export const calculateEnquiriesStatisticsSum = (enquiriesStatistics, longLeaseEnquiriesStatistics) => {
  const sumEnquiriesStatistics =
    enquiriesStatistics["partial-payment-done"] +
    enquiriesStatistics["full-payment-done"] +
    enquiriesStatistics["assign-contact-person"] +
    enquiriesStatistics["flight-activation-inprogress"] +
    enquiriesStatistics["flight-confirmed"] +
    enquiriesStatistics["go-live"];

  const sumLongLeaseEnquiriesStatistics =
    longLeaseEnquiriesStatistics["partial-payment-done"] +
    longLeaseEnquiriesStatistics["full-payment-done"] +
    longLeaseEnquiriesStatistics["assign-contact-person"] +
    longLeaseEnquiriesStatistics["flight-activation-inprogress"] +
    longLeaseEnquiriesStatistics["flight-confirmed"] +
    longLeaseEnquiriesStatistics["go-live"];

  const totalSum = sumEnquiriesStatistics + sumLongLeaseEnquiriesStatistics;

  return totalSum < 10 ? `0${totalSum}` : `${totalSum}`;
};