import { actionTypes } from "../constants/actionTypes";
import serviceBase from "../../services/interceptorServices";
import ApplicationConsts from "../../applicationConstants";
import axios from "axios";

export const fetchAggregatorLoginDetailsSuccess = (post) => {
  return {
    type: actionTypes.MOBILE_EMAIL_VERIFICATION,
    fetchAggregatorLoginDetailsSuccess: post,
  };
};
export const verifyOTPSuccess = (post) => {
  return {
    type: actionTypes.OTP_VERIFY,
    verifyOTPSuccess: post,
  };
};
export const fetchAggregatorSoftStatisticsSuccess = (post) => {
  return {
    type: actionTypes.GET_SOFT_STATISTICS,
    fetchAggregatorSoftStatisticsSuccess: post,
  };
};

export const operatorAircraftsDetails = (post) => {
  return {
    type: actionTypes.FETCH_OPERATOR_AIRCRAFTS_DETAILS,
    operatorAircraftsDetails: post,
  };
};

export const getDGCAOperatorsDataSuccess = (post) => {
  return {
    type: actionTypes.FETCH_OPERATOR_DGCA_DETAILS,
    getDGCAOperatorsDataSuccess: post,
  };
};
export const fetchIndividualOperatorDetailsSuccess = (post) => {
  return {
    type: actionTypes.FETCH_INDIVIDUAL_OPERATOR_DETAILS,
    fetchIndividualOperatorDetailsSuccess: post,
  };
};
export const fetchIndividualAgentDetailsSuccess = (post) => {
  return {
    type: actionTypes.FETCH_INDIVIDUAL_AGENT_DETAILS,
    fetchIndividualAgentDetailsSuccess: post,
  };
};

export const fetchIndividualAircraftDetailsSuccess = (post) => {
  return {
    type: actionTypes.FETCH_INDIVIDUAL_AIRCRAFT_DETAILS,
    fetchIndividualAircraftDetailsSuccess: post,
  };
};

export const fetchIndividualOperatorAircraftDetailsSuccess = (post) => {
  return {
    type: actionTypes.FETCH_INDIVIDUAL_OPERATOR_AIRCRAFTS_DETAILS,
    fetchIndividualOperatorAircraftDetailsSuccess: post,
  };
};

export const fetchOperatorDgcaAircraftDetailsSuccess = (post) => {
  return {
    type: actionTypes.FETCH_INDIVIDUAL_OPERATOR_DGCA_AIRCRAFTS_DETAILS,
    fetchOperatorDgcaAircraftDetailsSuccess: post,
  };
};

export const fetchPassengersDataSuccess = (post) => {
  return {
    type: actionTypes.FETCH_PASSANGERS_DETAILS,
    fetchPassengersDataSuccess: post,
  };
};
export const fetchAllOperatorsDataSuccess = (post) => {
  return {
    type: actionTypes.FETCH_OPERATOR_DETAILS,
    fetchAllOperatorsDataSuccess: post,
  };
};
export const fetchAircraftsDataSuccess = (post) => {
  return {
    type: actionTypes.FETCH_AIRCRAFTS_DETAILS,
    fetchAircraftsDataSuccess: post,
  };
};
export const patchBookingAddPassengerDetailsSuccess = (post) => {
  return {
    type: actionTypes.POST_BOOKING_ADD_PASSENGERS,
    patchBookingAddPassengerDetailsSuccess: post,
  };
};

export const postPaymentCheckoutSuccess = (post) => {
  return {
    type: actionTypes.POST_PAYMENT_CHECKOUT,
    postPaymentCheckoutSuccess: post,
  };
};

export const fetchNSOPoperatorsDataSuccess = (post) => {
  return {
    type: actionTypes.FETCH_NSOP_OPERATOR_DETAILS,
    fetchNSOPoperatorsDataSuccess: post,
  };
};
export const fetchAllDGCAOperatorsDataSuccess = (post) => {
  return {
    type: actionTypes.FETCH_ALL_DGCA_OPERATORS_DETAILS,
    fetchAllDGCAOperatorsDataSuccess: post,
  };
};

export const fetchAggregatorStaffDataSuccess = (post) => {
  return {
    type: actionTypes.FETCH_ONBOARDED_STAFF_DETAILS,
    fetchAggregatorStaffDataSuccess: post,
  };
};

export const fetchGuestDetailsSuccess = (post) => {
  return {
    type: actionTypes.FETCH_GUEST_DETAILS,
    fetchGuestDetailsSuccess: post,
  };
};

export const setAddOperatorDetailsSuccess = (post) => {
  return {
    type: actionTypes.SET_ADD_OPERATOR_DETAILS,
    setAddOperatorDetailsSuccess: post,
  };
};

export const fetchAllOnboarededAgentsDataSuccess = (post) => {
  return {
    type: actionTypes.FETCH_ONBOARDED_AGENTS_DATA,
    fetchAllOnboarededAgentsDataSuccess: post,
  };
};

export const fetchAllOperatorQuotesSuccess = (post) => {
  return {
    type: actionTypes.FETCH_ONBOARDED_OPERATOR_QUOTES,
    fetchAllOperatorQuotesSuccess: post,
  };
};

export const AddAggregatorStaffDataSuccess = (post) => {
  return {
    type: actionTypes.SET_ADD_AGGREGATOR_STAFF_DETAILS,
    AddAggregatorStaffDataSuccess: post,
  };
};
export const deleteAggregatorstaffSuccess = (post) => {
  return {
    type: actionTypes.SET_DELETE_AGGREGATOR_STAFF_DETAILS,
    deleteAggregatorstaffSuccess: post,
  };
};
export const editAggregatorstaffDetailsSuccess = (post) => {
  return {
    type: actionTypes.SET_EDIT_AGGREGATOR_STAFF_DETAILS,
    editAggregatorstaffDetailsSuccess: post,
  };
};

export const setAddAgentDetailsSuccess = (post) => {
  return {
    type: actionTypes.SET_ADD_AGENT_DETAILS,
    setAddAgentDetailsSuccess: post,
  };
};

export const setAgentStatusUpdateSuccess = (post) => {
  return {
    type: actionTypes.PATCH_AGENTS_STATUS,
    setAgentStatusUpdateSuccess: post,
  };
};
export const fetchOperatorEnquiresSuccess = (post) => {
  return {
    type: actionTypes.GET_OPERATOR_ENQUIRIES,
    fetchOperatorEnquiresSuccess: post,
  };
};
export const fetchLongLeaseEnquiresSuccess = (post) => {
  return {
    type: actionTypes.GET_LONG_LEASE_ENQUIRIES,
    fetchLongLeaseEnquiresSuccess: post,
  };
};

export const fetchIndividualLongLeaseDetailsSuccess = (post) => {
  return {
    type: actionTypes.GET_INDIVIDUAL_LONG_LEASE_DETAILS,
    fetchIndividualLongLeaseDetailsSuccess: post,
  };
};

export const fetchIndividualBookingsSuccess = (post) => {
  return {
    type: actionTypes.GET_INDIVIDUAL_BOOKINGS,
    fetchIndividualBookingsSuccess: post,
  };
};
export const fetchSearchAircrftsDetailsSuccess = (post) => {
  return {
    type: actionTypes.FETCH_SEARCH_AIRCRAFTS_DETAILS,
    fetchSearchAircrftsDetailsSuccess: post,
  };
};
export const fetchSearchLongLeaseAircraftsDetailsSuccess = (post) => {
  return {
    type: actionTypes.FETCH_SEARCH_LONG_LEASE_AIRCRAFTS_DETAILS,
    fetchSearchLongLeaseAircraftsDetailsSuccess: post,
  };
};
export const fetchSearchAirportsDetailsSuccess = (post) => {
  return {
    type: actionTypes.FETCH_SEARCH_AIRPORTS_DETAILS,
    fetchSearchAirportsDetailsSuccess: post,
  };
};

export const fetchOnboardedAircraftsDetailsSuccess = (post) => {
  return {
    type: actionTypes.FETCH_ONBOARDED_AIRCRAFTS_DETAILS,
    fetchOnboardedAircraftsDetailsSuccess: post,
  };
};
export const fetchAllPaymentDetailsSuccess = (post) => {
  return {
    type: actionTypes.FETCH_ALL_PAYMENT_DETAILS,
    fetchAllPaymentDetailsSuccess: post,
  };
};

export const fetchSoftPaymentDetailsSuccess = (post) => {
  return {
    type: actionTypes.FETCH_SOFT_PAYMENTS,
    fetchSoftPaymentDetailsSuccess: post,
  };
};

export const setOperatorStatusUpdateSuccess = (post) => {
  return {
    type: actionTypes.PATCH_OPERATOR_STATUS,
    setOperatorStatusUpdateSuccess: post,
  };
};

export const setAircraftStatusUpdateSuccess = (post) => {
  return {
    type: actionTypes.PATCH_AIRCRAFT_STATUS,
    setAircraftStatusUpdateSuccess: post,
  };
};

export const patchOperatorDocumentsSuccess = (post) => {
  return {
    type: actionTypes.PATCH_OPERATOR_DOCUMENTS,
    patchOperatorDocumentsSuccess: post,
  };
};

export const patchBoardOfDirectorsSuccess = (post) => {
  return {
    type: actionTypes.PATCH_BOARDOFDIRECTORS,
    patchBoardOfDirectorsSuccess: post,
  };
};

export const patchBankDetailsSuccess = (post) => {
  return {
    type: actionTypes.PATCH_BANKDETAILS,
    patchBankDetailsSuccess: post,
  };
};

export const patchOperatorDetailsSuccess = (post) => {
  return {
    type: actionTypes.PATCH_OPERATOR_DETAILS,
    patchOperatorDetailsSuccess: post,
  };
};

export const patchRegistrationTypeSuccess = (post) => {
  return {
    type: actionTypes.PATCH_REGISTRATION_TYPE,
    patchRegistrationTypeSuccess: post,
  };
};

export const patchAgentDocumentsSuccess = (post) => {
  return {
    type: actionTypes.PATCH_AGENT_DOCUMENTS,
    patchAgentDocumentsSuccess: post,
  };
};

export const patchAgentDetailsSuccess = (post) => {
  return {
    type: actionTypes.PATCH_AGENT_DETAILS,
    patchAgentDetailsSuccess: post,
  };
};

export const postAircraftsDetailsSuccess = (post) => {
  return {
    type: actionTypes.POST_AIRCRAFT_DETAILS,
    postAircraftsDetailsSuccess: post,
  };
};

export const fetchFerryAndIdealDaysDetailsSuccess = (post) => {
  return {
    type: actionTypes.FETCH_FERRY_AND_IDEAL_DAYS,
    fetchFerryAndIdealDaysDetailsSuccess: post,
  };
};

export const PatchIntrestedInFerrySuccess = (post) => {
  return {
    type: actionTypes.PATCH_INTRESTED_FERRY,
    PatchIntrestedInFerrySuccess: post,
  };
};

export const setPaymentVerificationStatusUpdateSuccess = (post) => {
  return {
    type: actionTypes.UPDATE_PAYMENT_VERIFICATION_STATUS,
    setPaymentVerificationStatusUpdateSuccess: post,
  };
};

export const patchAircraftDetailsSuccess = (post) => {
  return {
    type: actionTypes.PATCH_AIRCRAFT_DETAILS,
    patchAircraftDetailsSuccess: post,
  };
};
export const postGetQuoteSuccess = (post) => {
  return {
    type: actionTypes.POST_GET_QUOTE,
    postGetQuoteSuccess: post,
  };
};

export const postLongLeaseGetQuoteSuccess = (post) => {
  return {
    type: actionTypes.POST_GET_LONG_LEASE_QUOTE,
    postLongLeaseGetQuoteSuccess: post,
  };
};

export const postTechHaltDetailsSuccess = (post) => {
  return {
    type: actionTypes.POST_TECH_HALT_DETAILS,
    postTechHaltDetailsSuccess: post,
  };
};

export const postAddPassengerDetailsSuccess = (post) => {
  return {
    type: actionTypes.POST_ADD_PASSENGER_DETAILS,
    postAddPassengerDetailsSuccess: post,
  };
};

export const postAddPassengerGroupDetailsSuccess = (post) => {
  return {
    type: actionTypes.POST_ADD_PASSENGER_GROUP_DETAILS,
    postAddPassengerGroupDetailsSuccess: post,
  };
};

export const getPassengerGroupDetailsSuccess = (post) => {
  return {
    type: actionTypes.GET_PASSENGER_GROUP_DETAILS,
    getPassengerGroupDetailsSuccess: post,
  };
};

export const patchTicketStatusSuccess = (post) => {
  return {
    type: actionTypes.PATCH_TICKET_STATUS,
    patchTicketStatusSuccess: post,
  };
};

export const patchPassengerDetailsSuccess = (post) => {
  return {
    type: actionTypes.PATCH_PASSENGER_DETAILS,
    patchPassengerDetailsSuccess: post,
  };
};
export const patchPassengerGroupDetailsSuccess = (post) => {
  return {
    type: actionTypes.PATCH_PASSENGER_GROUP_DETAILS,
    patchPassengerGroupDetailsSuccess: post,
  };
};

export const deletePassengerDetailsSuccess = (post) => {
  return {
    type: actionTypes.DELETE_PASSENGER_DETAILS,
    deletePassengerDetailsSuccess: post,
  };
};

export const getCrewDetailsSuccess = (post) => {
  return {
    type: actionTypes.GET_CREW_DETAILS,
    getCrewDetailsSuccess: post,
  };
};

export const postCrewDetailsSuccess = (post) => {
  return {
    type: actionTypes.POST_CREW_DETAILS,
    postCrewDetailsSuccess: post,
  };
};

export const patchDeleteCrewDetailsSuccess = (post) => {
  return {
    type: actionTypes.PATCH_DELETE_CREW_DETAILS,
    patchDeleteCrewDetailsSuccess: post,
  };
};
export const patchAircraftStatusUpdateSuccess = (post) => {
  return {
    type: actionTypes.PATCH_AIRCRAFT_VERIFICATION,
    patchAircraftStatusUpdateSuccess: post,
  };
};

export const patchCrewDetailsSuccess = (post) => {
  return {
    type: actionTypes.PATCH_CREW_DETAILS,
    patchCrewDetailsSuccess: post,
  };
};

export const getWalletDetailsSuccess = (post) => {
  return {
    type: actionTypes.GET_WALLET_DETAILS,
    getWalletDetailsSuccess: post,
  };
};

export const postWalletDetailsSuccess = (post) => {
  return {
    type: actionTypes.POST_WALLET_DETAILS,
    postWalletDetailsSuccess: post,
  };
};

export const getAircraftLiveLocationDetailsSuccess = (post) => {
  return {
    type: actionTypes.GET_AIRCRAFT_LIVE_LOCATION,
    getAircraftLiveLocationDetailsSuccess: post,
  };
};

export const postSendEnquiryFareDetailsSuccess = (post) => {
  return {
    type: actionTypes.POST_SEND_ENQUIRY_FARE_DETAILS,
    postSendEnquiryFareDetailsSuccess: post,
  };
};
export const postSendLongLeaseEnquiryFareDetailsSuccess = (post) => {
  return {
    type: actionTypes.POST_SEND_LONG_LEASE_ENQUIRY_FARE_DETAILS,
    postSendLongLeaseEnquiryFareDetailsSuccess: post,
  };
};

export const patchRejectBookingSuccess = (post) => {
  return {
    type: actionTypes.PATCH_REJECT_BOOKING,
    patchRejectBookingSuccess: post,
  };
};
export const patchRejectLongLeaseSuccess = (post) => {
  return {
    type: actionTypes.PATCH_REJECT_LONG_LEASE,
    patchRejectLongLeaseSuccess: post,
  };
};

export const fetchIndividualAssignedTicketsDetailsSuccess = (post) => {
  return {
    type: actionTypes.GET_INDIVIDUAL_TICKETS,
    fetchIndividualAssignedTicketsDetailsSuccess: post,
  };
};
export const fetchIndividualTicketDetailsSuccess = (post) => {
  return {
    type: actionTypes.GET_INDIVIDUAL_TICKET_DETAILS,
    fetchIndividualTicketDetailsSuccess: post,
  };
};
export const fetchLongLeaseStatisticsSuccess = (post) => {
  return {
    type: actionTypes.GET_LONG_LEASE_STATISTICS_DETAILS,
    fetchLongLeaseStatisticsSuccess: post,
  };
};

export const postAssignCrewSuccess = (post) => {
  return {
    type: actionTypes.POST_ASSIGN_CREW,
    postAssignCrewSuccess: post,
  };
};
export const postGoLiveSuccess = (post) => {
  return {
    type: actionTypes.POST_GO_LIVE,
    postGoLiveSuccess: post,
  };
};
export const postIndividualCommentSuccess = (post) => {
  return {
    type: actionTypes.POST_INDIVIDUAL_TICKET_COMMENT,
    postIndividualCommentSuccess: post,
  };
};
// export const postMessageSuccess = post => {
//   return {
//     type: actionTypes.POST_INDIVIDUAL_TICKET_COMMENT,
//     postMessageSuccess: post
//   }
// }
export const getIndividualChatRoomDataSuccess = (post) => {
  return {
    type: actionTypes.GET_CHAT_ROOM_DATA,
    getIndividualChatRoomData: post,
  };
};
export const fetchChatRoomsSuccess = (post) => {
  return {
    type: actionTypes.GET_CHAT_ROOMS,
    fetchChatRoomsSuccess: post,
  };
};

export const fetchNotificationsLogsSuccess = (post) => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_LOG,
    fetchNotificationsLogsSuccess: post,
  };
};

export const patchVerifyPassengersSuccess = (post) => {
  return {
    type: actionTypes.PATCH_VERIFY_PASSENGERS,
    patchVerifyPassengersSuccess: post,
  };
};

export const cancelBookingSuccess = (post) => {
  return {
    type: actionTypes.PATCH_CANCEL_BOOKING,
    cancelBookingSuccess: post,
  };
};

export const getOperatorSettingsSuccess = (post) => {
  return {
    type: actionTypes.GET_OPERATOR_SETTINGS,
    getOperatorSettingsSuccess: post,
  };
};

export const patchOperatorSettingsSuccess = (post) => {
  return {
    type: actionTypes.PATCH_OPERATOR_SETTINGS,
    patchOperatorSettingsSuccess: post,
  };
};

export const fetchGlobalConstantsSuccess = (post) => {
  return {
    type: actionTypes.FETCH_GLOBAL_CONSTANTS,
    fetchGlobalConstantsSuccess: post,
  };
};
export const postAircraftAvailabilitySuccess = (post) => {
  return {
    type: actionTypes.POST_AIRCRAFT_AVAILIBILITY,
    postAircraftAvailabilitySuccess: post,
  };
};

export const postBookingPushNotificationsSuccess = (post) => {
  return {
    type: actionTypes.BOOKING_PUSH_NOTIFICATIONS,
    postBookingPushNotificationsSuccess: post,
  };
};

export const postPaymentReminderSuccess = (post) => {
  return {
    type: actionTypes.POST_PAYMENT_REMINDER,
    postPaymentReminderSuccess: post,
  };
};

/**  ********************************************
 Function for Customer Quote Request Info
 ********************************************  */
export const getCustomerQuoteRequestInfoSuccess = (post) => {
  return {
    type: actionTypes.CUSTOMER_QUOTE_REQUEST_INFO,
    getCustomerQuoteRequestInfoSuccess: post,
  };
};
export const postUpdateBookingLegSuccess = (post) => {
  return {
    type: actionTypes.UPDATE_BOOKING_LEG,
    postUpdateBookingLegSuccess: post,
  };
};

/**  ********************************************
 Function for ferry checkout payment
 ********************************************  */
export const postFerryCheckoutPaymentSuccess = (post) => {
  return {
    type: actionTypes.FERRY_CHECKOUT_PAYMENT,
    postFerryCheckoutPaymentSuccess: post,
  };
};

/**  ********************************************
 Function for ferry checkout payment
 ********************************************  */
export const postCCAVRequestHandlerSuccess = (post) => {
  return {
    type: actionTypes.CCAV_REQUEST_HANDLER,
    postCCAVRequestHandlerSuccess: post,
  };
};

/**  ********************************************
 Function for CCAV Transaction details
 ********************************************  */
export const postCCAVTransactionDetailsSuccess = (post) => {
  return {
    type: actionTypes.CCAV_TRANSACTION_DETAILS,
    postCCAVTransactionDetailsSuccess: post,
  };
};

/**  ********************************************
 Function for CCAV Wallet details
 ********************************************  */
export const postCCAVRequestWalletHandlerSuccess = (post) => {
  return {
    type: actionTypes.CCAV_REQUEST_WALLET_HANDLER,
    postCCAVRequestWalletHandlerSuccess: post,
  };
};

/**  ********************************************
 Function for Passenger reminder success
 ********************************************  */
export const getPassengerReminderSuccess = (post) => {
  return {
    type: actionTypes.GET_PASSENGER_REMINDER,
    getPassengerReminderSuccess: post,
  };
};

/**  ********************************************
 Function for paypal checkout payment
 ********************************************  */
export const postPaypalRequestHandlerSuccess = (post) => {
  return {
    type: actionTypes.PAYPAL_REQUEST_HANDLER,
    postPaypalRequestHandlerSuccess: post,
  };
};
/**  ********************************************
 Function for Paypal Transaction details success
 ********************************************  */
export const postPaypalTransactionDetailsSuccess = (post) => {
  return {
    type: actionTypes.PAYPAL_RESPONSE_HANDLER,
    postPaypalTransactionDetailsSuccess: post,
  };
};

/**  ********************************************
 Function for Paypal Transaction details cancel
 ********************************************  */
export const postPaypalTransactionDetailsCancel = (post) => {
  return {
    type: actionTypes.PAYPAL_CANCEL_HANDLER,
    postPaypalTransactionDetailsCancel: post,
  };
};

/**  ********************************************
 Function for Paypal Wallet details
 ********************************************  */
export const postPaypalRequestWalletHandlerSuccess = (post) => {
  return {
    type: actionTypes.PAYPAL_REQUEST_WALLET_HANDLER,
    postPaypalRequestWalletHandlerSuccess: post,
  };
};

/**  ********************************************
 Function for Currency converter
 ********************************************  */
export const postCurrencyConverterSuccess = (post) => {
  return {
    type: actionTypes.CURRENCY_CONVERTER,
    postCurrencyConverterSuccess: post,
  };
};

/**  ********************************************
 Function to get AWS credentials
 ********************************************  */
export const postAWSCredentialsSuccess = (post) => {
  return {
    type: actionTypes.AWS_CREDENTIALS,
    postAWSCredentialsSuccess: post,
  };
};

export const setMapApiLoaderSuccess = (post) => {
  return {
    type: actionTypes.API_LOADER,
    setMapApiLoaderSuccess: post,
  };
};


// ******* FAILE CASE ********
export const fetchActionfail = (error) => {
  console.log(error, "error");
  const errorLog = {
    type: actionTypes.FETCH_ACTION_FAIL,
    data: error.response,
  };
  return errorLog;
};

export const fetchAggregatorLoginDetails = (data, callback) => {
  return async (dispatch) => {
    localStorage.setItem("mobileNumber", data.mobileEmail);
    let config = {
      headers: {
        cdomain: "https://operator.dev.softairlines.com/",
      },
    };
    await axios
      .post(
        `${ApplicationConsts.SOFT_BASE_URL}user-operations/verify-mobile-email`,
        data,
        config
      )
      .then(async (response) => {
        await dispatch(fetchAggregatorLoginDetailsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        callback(error);
      });
  };
};

export const verifyUserRegistrationOTP = (data, callback) => {
  return async (dispatch) => {
    await axios
      .post(
        `${ApplicationConsts.SOFT_BASE_URL}user-operations/verify-otp`,
        data
      )
      .then(async (response) => {
        localStorage.setItem(
          "accessToken",
          response.data.data.session.accessToken
        );
        localStorage.setItem("userId", response.data.data.session.userId);
        localStorage.setItem("email", response.data.data.user.email);
        localStorage.setItem("role", response.data.data.user.role);
        localStorage.setItem("firstName", response.data.data.user.firstName);
        localStorage.setItem("lastName", response.data.data.user.lastName);
        localStorage.setItem(
          "userPhoneNumber",
          response.data.data.user.phoneNumber
        );
        localStorage.setItem(
          "profileImage1",
          response.data.data.user.profileImage
        );
        localStorage.setItem(
          "operatorMasterId",
          response.data.data.userProperties.operatorMasterId
        );
        localStorage.setItem(
          "referenceInfo",
          JSON.stringify(response.data.data.user.referenceInfo)
        );
        localStorage.setItem(
          "referenceId",
          response.data.data.session.referenceInfo.referenceId
        );
        localStorage.setItem(
          "referenceType",
          response.data.data.session.referenceInfo.referenceType
        );
        localStorage.setItem(
          "registrationType",
          response.data.data.user.registrationType
        );
        localStorage.setItem("userId", response.data.data.user._id);
        localStorage.setItem(
          "operatorId",
          response.data.data.userProperties.referenceId
        );
        localStorage.setItem(
          "refType",
          response.data.data.session.referenceInfo.refType
        );
        localStorage.setItem(
          "emailSettings",
          response.data.data.user.settings.email
        );
        localStorage.setItem(
          "pushSettings",
          response.data.data.user.settings.push
        );
        localStorage.setItem(
          "reminderSettings",
          response.data.data.user.settings.reminder
        );
        localStorage.setItem(
          "smsSettings",
          response.data.data.user.settings.sms
        );
        localStorage.setItem(
          "whatsappSettings",
          response.data.data.user.settings.whatsapp
        );
        localStorage.setItem(
          "pageAccess",
          JSON.stringify(response.data.data.user.pageAccess)
        );
        await dispatch(verifyOTPSuccess(response.data.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

export const fetchAggregatorSoftStatistics = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(`${ApplicationConsts.SOFT_BASE_URL}aggregator/get-soft-statistics`)
      .then(async (response) => {
        await dispatch(fetchAggregatorSoftStatisticsSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};
export const getDGCAOperatorsData = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(`${ApplicationConsts.SOFT_BASE_URL}operators/get-dgca-operators`)
      .then(async (response) => {
        await dispatch(getDGCAOperatorsDataSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
      });
  };
};

export const fetchOperatorAircraftsDetails = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}operator-aircrafts/get-dgca-operator-aircrafts/63beb227e757ebfbde366dcd`
      )
      .then(async (response) => {
        await dispatch(operatorAircraftsDetails(response.data.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
      });
  };
};

export const fetchIndividualOperatorDetails = (data, callback) => {
  const { operatorId } = data;
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}operators/get-operator/${operatorId}`
      )
      .then(async (response) => {
        await dispatch(fetchIndividualOperatorDetailsSuccess(response.data));
        await callback(response.data);

        localStorage.setItem(
          "operatorMasterId",
          response.data.data.operatorDetails.operatorMasterId
        );
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

export const fetchIndividualAgentDetails = (data, callback) => {
  const { agentId } = data;
  return async (dispatch) => {
    await serviceBase
      .get(`${ApplicationConsts.SOFT_BASE_URL}agents/get-agent/${agentId}`)
      .then(async (response) => {
        await dispatch(fetchIndividualAgentDetailsSuccess(response.data));
        await callback(response.data);
        // localStorage.setItem('operatorMasterId',response.data.data.operatorDetails.agentId)
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

export const fetchIndividualAircraftDetails = (data, callback) => {
  const { aircraftId } = data;
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}operator-aircrafts/get-aircraft-details/${aircraftId}`
      )
      .then(async (response) => {
        await dispatch(fetchIndividualAircraftDetailsSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

export const fetchIndividualOperatorAircraftDetails = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}operator-aircrafts/get-operator-aircraft-list/${data.referenceId}`
      )
      .then(async (response) => {
        await dispatch(
          fetchIndividualOperatorAircraftDetailsSuccess(response.data)
        );
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

export const fetchOperatorDgcaAircraftDetails = (
  operatorMasterId,
  callback
) => {
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}operator-aircrafts/get-dgca-operator-aircrafts/${operatorMasterId}`
      )
      .then(async (response) => {
        await dispatch(fetchOperatorDgcaAircraftDetailsSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

export const fetchPassengersData = (data, callback) => {
  const { operatorId, referenceType } = data;
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}passengers/all-passengers?referenceId=${operatorId}&referenceType=${referenceType}`
      )
      .then(async (response) => {
        await dispatch(fetchPassengersDataSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};
export const fetchAllOperatorsData = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}aggregator/get-onboarded-operators`
      )
      .then(async (response) => {
        await dispatch(fetchAllOperatorsDataSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

export const fetchNSOPoperatorAircraftsData = (data, callback) => {
  const { operatorId } = data;
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}operator-aircrafts/get-dgca-operator-aircrafts/${operatorId}`
      )
      .then(async (response) => {
        await dispatch(fetchAircraftsDataSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

export const fetchNSOPoperatorsData = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(`${ApplicationConsts.SOFT_BASE_URL}operators/get-dgca-operators`)
      .then(async (response) => {
        await dispatch(fetchNSOPoperatorsDataSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

export const fetchAllDGCAOperatorsData = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(`${ApplicationConsts.SOFT_BASE_URL}operators/get-dgca-operators`)
      .then(async (response) => {
        await dispatch(fetchAllDGCAOperatorsDataSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

export const fetchAggregatorStaffData = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}aggregator/get-onboarded-aggregators`
      )
      .then(async (response) => {
        await dispatch(fetchAggregatorStaffDataSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};
export const AddAggregatorStaffData = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(
        `${ApplicationConsts.SOFT_BASE_URL}user-operations/create-user`,
        data
      )
      .then(async (response) => {
        await dispatch(AddAggregatorStaffDataSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

export const fetchGuestDetails = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(`${ApplicationConsts.SOFT_BASE_URL}aggregator/get-onboarded-guests`)
      .then(async (response) => {
        await dispatch(fetchGuestDetailsSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
      });
  };
};

export const setAddOperatorDetails = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(`${ApplicationConsts.SOFT_BASE_URL}operators/add-operator`, data)
      .then(async (response) => {
        await dispatch(setAddOperatorDetailsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error);
      });
  };
};

export const deleteAggregatorstaff = (staff_id, callback) => {
  let data = {};
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}user-operations/delete-user/${staff_id}`,
        data
      )
      .then(async (response) => {
        await dispatch(deleteAggregatorstaffSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error);
      });
  };
};

export const setAddAgentDetails = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(`${ApplicationConsts.SOFT_BASE_URL}agents/add-agent`, data)
      .then(async (response) => {
        await dispatch(setAddAgentDetailsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error);
      });
  };
};

export const editAggregatorstaffDetails = (staff_id, newObject, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}user-operations/update-user/${staff_id}`,
        newObject
      )
      .then(async (response) => {
        await dispatch(editAggregatorstaffDetailsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error);
      });
  };
};

export const fetchAllOnboarededAgentsData = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}aggregator/get-onboarded-agents`,
        data
      )
      .then(async (response) => {
        await dispatch(fetchAllOnboarededAgentsDataSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error);
      });
  };
};

export const fetchAllOperatorQuotes = (data, callback) => {
  const { referenceType, referenceId } = data;
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}bookings/get-bookings?referenceType=${referenceType}&referenceId=${referenceId}`,
        data
      )
      .then(async (response) => {
        await dispatch(fetchAllOperatorQuotesSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error);
      });
  };
};
export const fetchOperatorEnquires = (data, callback) => {
  const { operatorId, referenceType } = data;
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}bookings/get-bookings?referenceType=${referenceType}&referenceId=${operatorId}`,
        {
          params: {
            ...{ type: "web" },
          },
        }
      )
      .then(async (response) => {
        await dispatch(fetchOperatorEnquiresSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {        
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

export const fetchLongLeaseEnquires = (data, callback) => {
  const { referenceId, referenceType } = data;
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}long-lease/get-long-lease-bookings?referenceType=${referenceType}&referenceId=${referenceId}`,
        {
          params: {
            ...{ type: "web" },
          },
        }
      )
      .then(async (response) => {
        await dispatch(fetchLongLeaseEnquiresSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        console.log(error, "error");
        
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

// export const fetchLongLeaseStatistics = (data, callback) => {
//   const { referenceId, referenceType } = data;
//   return async (dispatch) => {
//     await serviceBase
//       .get(
//         `${ApplicationConsts.SOFT_BASE_URL}long-lease/get-long-lease-bookings-statistics?referenceType=${referenceType}&referenceId=${referenceId}&type=web`
//       )
//       .then(async (response) => {
//         await dispatch(fetchLongLeaseStatisticsSuccess(response.data.data));
//         await callback(response.data);
//       })
//       .catch(async (error) => {
//         dispatch(fetchActionfail(error));
//         await callback(error.response.data);
//       });
//   };
// };

export const fetchIndividualLongLeaseDetails = (object, callback) => {
  const { longLeaseId } = object;
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}long-lease/get-single-long-lease-booking-info/${longLeaseId}`
      )
      .then(async (response) => {
        await dispatch(
          fetchIndividualLongLeaseDetailsSuccess(response.data.data)
        );
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error);
      });
  };
};

export const fetchIndividualBookings = (object, callback) => {
  const { bookingId } = object;
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}bookings/get-single-booking-info/${bookingId}`
      )
      .then(async (response) => {
        await dispatch(fetchIndividualBookingsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error);
      });
  };
};

export const setAircraftStatusUpdate = (
  aircraft_id,
  aircraftStatusUpdate,
  callback
) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}operator-aircrafts/update-aircraft-status/${aircraft_id}`,
        aircraftStatusUpdate
      )
      .then(async (response) => {
        await dispatch(setAircraftStatusUpdateSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const fetchOnboardedAircraftsDetails = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}aggregator/get-onboarded-aircrafts`
      )
      .then(async (response) => {
        await dispatch(
          fetchOnboardedAircraftsDetailsSuccess(response.data.data)
        );
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error);
      });
  };
};

export const fetchSearchAirportsDetails = (data, callback) => {
  let { location, type } = data;
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}aircrafts/search-airports-cities?location=${location}&fleetType=${type}`
      )
      // await axios.get(`${ApplicationConsts.SOFT_BASE_URL}aircrafts/search-airports-cities?location=Shamshabad`,config)
      .then(async (response) => {
        await dispatch(fetchSearchAirportsDetailsSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
      });
  };
};

export const fetchSearchAircrftsDetails = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(
        `${ApplicationConsts.SOFT_BASE_URL}aircrafts/search-aircrafts`,
        data
      )
      .then(async (response) => {
        await dispatch(fetchSearchAircrftsDetailsSuccess(response.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.data);
      });
  };
};

export const fetchSearchLongLeaseAircraftsDetails = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(
        `${ApplicationConsts.SOFT_BASE_URL}aircrafts/search-long-lease-aircrafts`,
        data
      )
      .then(async (response) => {
        await dispatch(
          fetchSearchLongLeaseAircraftsDetailsSuccess(response.data)
        );
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.data);
      });
  };
};

export const fetchAllPaymentDetails = (payLoadObjURL, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(payLoadObjURL)
      .then(async (response) => {
        await dispatch(fetchAllPaymentDetailsSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
      });
  };
};
export const setAgentStatusUpdate = (agent_id, AgentStatusUpdate, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}agents/update-agent-status/${agent_id}`,
        AgentStatusUpdate
      )
      .then(async (response) => {
        await dispatch(setAgentStatusUpdateSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const patchOperatorDocuments = (op_id, arr, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}operators/update-operator-documents/${op_id}`,
        arr
      )
      .then(async (response) => {
        await dispatch(patchOperatorDocumentsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const patchBoardOfDirectors = (id, arr, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}operators/update-operator-board-of-directors/${id}`,
        arr
      )
      .then(async (response) => {
        await dispatch(patchBoardOfDirectorsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const patchBankDetails = (id, arr, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}operators/update-operator-bank-details/${id}`,
        arr
      )
      .then(async (response) => {
        await dispatch(patchBankDetailsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const patchOperatorDetails = (op_id, obj, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}operators/update-operator-details/${op_id}`,
        obj
      )
      .then(async (response) => {
        await dispatch(patchOperatorDetailsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const patchAgentDocuments = (ag_id, arr, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}agents/update-agent-documents/${ag_id}`,
        arr
      )
      .then(async (response) => {
        await dispatch(patchAgentDocumentsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const patchAgentDetails = (ag_id, obj, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}agents/update-agent-details/${ag_id}`,
        obj
      )
      .then(async (response) => {
        await dispatch(patchAgentDetailsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const fetchSoftPaymentDetails = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(`${ApplicationConsts.SOFT_BASE_URL}aggregator/get-soft-payments`)
      .then(async (response) => {
        await dispatch(fetchSoftPaymentDetailsSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};
export const fetchFerryAndIdealDaysDetails = (data, callback) => {
  const { isfilterType } = data;
  return async (dispatch) => {
    await serviceBase
      .get(`${ApplicationConsts.SOFT_BASE_URL}ferry/get-ferry-legs`, {
        params: {
          ...(isfilterType ? { filterType: "upcoming" } : {}),
        },
      })
      .then(async (response) => {
        await dispatch(fetchFerryAndIdealDaysDetailsSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

export const PatchIntrestedInFerry = (ferryMasterId, obj, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}ferry/interest-ferry-leg/${ferryMasterId}`,
        obj
      )
      .then(async (response) => {
        await dispatch(PatchIntrestedInFerrySuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const postAircraftsDetails = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(
        `${ApplicationConsts.SOFT_BASE_URL}operator-aircrafts/add-operator-aircraft`,
        data
      )
      .then(async (response) => {
        await dispatch(postAircraftsDetailsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};
export const setOperatorStatusUpdate = (operator_id, obj, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}operators/update-operator-status/${operator_id}`,
        obj
      )
      .then(async (response) => {
        await dispatch(setOperatorStatusUpdateSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const patchAircraftStatusUpdate = (aircraft_id, obj, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}operator-aircrafts/update-aircraft-status/${aircraft_id}`,
        obj
      )
      .then(async (response) => {
        await dispatch(patchAircraftStatusUpdateSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const setPaymentVerificationStatusUpdate = (
  payment_id,
  PaymentStatusUpdate,
  callback
) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}aggregator/update-booking-payment-details/${payment_id}`,
        PaymentStatusUpdate
      )
      .then(async (response) => {
        await dispatch(
          setPaymentVerificationStatusUpdateSuccess(response.data.data)
        );
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const patchAircraftDetails = (id, obj, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}operator-aircrafts/update-operator-aircraft/${id}`,
        obj
      )
      .then(async (response) => {
        await dispatch(patchAircraftDetailsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const postAddPassengerDetails = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(`${ApplicationConsts.SOFT_BASE_URL}passengers/add-passenger`, data)
      .then(async (response) => {
        await dispatch(postAddPassengerDetailsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const patchBookingAddPassengerDetails = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(`${ApplicationConsts.SOFT_BASE_URL}bookings/add-passenger`, data)
      .then(async (response) => {
        await dispatch(
          patchBookingAddPassengerDetailsSuccess(response.data.data)
        );
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const postAddPassengerGroupDetails = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(
        `${ApplicationConsts.SOFT_BASE_URL}passenger-groups/add-group`,
        data
      )
      .then(async (response) => {
        await dispatch(postAddPassengerGroupDetailsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const getPassengerGroupDetails = (data, callback) => {
  const { operatorId, referenceType } = data;
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}passenger-groups/all-passenger-groups/operator?referenceId=${operatorId}&referenceType=${referenceType}`
      )
      .then(async (response) => {
        await dispatch(getPassengerGroupDetailsSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

export const patchPassengerDetails = (id, obj, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}passengers/passenger/${id}`,
        obj
      )
      .then(async (response) => {
        await dispatch(patchPassengerDetailsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const patchPassengerGroupDetails = (id, obj, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}passenger-groups/groups/${id}`,
        obj
      )
      .then(async (response) => {
        await dispatch(patchPassengerGroupDetailsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const deletePassengerDetails = (id, callback) => {
  return async (dispatch) => {
    await serviceBase
      .delete(`${ApplicationConsts.SOFT_BASE_URL}passengers/passenger/${id}`)
      .then(async (response) => {
        await dispatch(deletePassengerDetailsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const getCrewDetails = (data, callback) => {
  const { operatorId, referenceType } = data;
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}booking-crew/get-crew-contact-persons?referenceId=${operatorId}&referenceType=${referenceType}`
      )
      .then(async (response) => {
        await dispatch(getCrewDetailsSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

export const postCrewDetails = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(
        `${ApplicationConsts.SOFT_BASE_URL}booking-crew/add-crew-contact-persons`,
        data
      )
      .then(async (response) => {
        await dispatch(postCrewDetailsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const postGetQuote = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(`${ApplicationConsts.SOFT_BASE_URL}bookings/request-quote`, data)
      .then(async (response) => {
        await dispatch(postGetQuoteSuccess(response.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const postLongLeaseGetQuote = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(`${ApplicationConsts.SOFT_BASE_URL}long-lease/request-quote`, data)
      .then(async (response) => {
        await dispatch(postLongLeaseGetQuoteSuccess(response.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const patchDeleteCrewDetails = (id, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}booking-crew/delete-crew-contact-persons/${id}`
      )
      .then(async (response) => {
        await dispatch(patchDeleteCrewDetailsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error);
      });
  };
};

export const patchCrewDetails = (crew_id, newObject, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}booking-crew/update-crew-contact-persons/${crew_id}`,
        newObject
      )
      .then(async (response) => {
        await dispatch(patchCrewDetailsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};
export const postSendEnquiryFareDetails = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(
        `${ApplicationConsts.SOFT_BASE_URL}bookings/send-enquiry-fair-details`,
        data
      )
      .then(async (response) => {
        await dispatch(postSendEnquiryFareDetailsSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

export const postSendLongLeaseEnquiryFareDetails = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(
        `${ApplicationConsts.SOFT_BASE_URL}long-lease/send-enquiry-fair-details`,
        data
      )
      .then(async (response) => {
        await dispatch(postSendLongLeaseEnquiryFareDetailsSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

export const PatchRejectBooking = (bookingId, data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}bookings/reject-booking/${bookingId}`,
        data
      )
      .then(async (response) => {
        await dispatch(patchRejectBookingSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};
export const patchRejectLongLease = (longLeaseId, data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}long-lease/reject-long-lease-booking/${longLeaseId}`,
        data
      )
      .then(async (response) => {
        await dispatch(patchRejectLongLeaseSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};


export const fetchIndividualAssignedTicketsDetails = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}ticket-management/get-aggregator-tickets/${data}`
      )
      .then(async (response) => {
        await dispatch(
          fetchIndividualAssignedTicketsDetailsSuccess(response.data.data)
        );
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};
export const fetchIndividualTicketDetails = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}ticket-management/get-ticket-history/${data.ticketId}`
      )
      .then(async (response) => {
        await dispatch(fetchIndividualTicketDetailsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const postAssignCrew = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(
        `${ApplicationConsts.SOFT_BASE_URL}bookings/add-contact-persons-crew-details`,
        data
      )
      .then(async (response) => {
        await dispatch(postAssignCrewSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const postGoLive = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(`${ApplicationConsts.SOFT_BASE_URL}bookings/go-live`, data)
      .then(async (response) => {
        await dispatch(postGoLiveSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};
export const postIndividualComment = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}ticket-management/update-ticket/${data.ticketId}`,
        data
      )
      .then(async (response) => {
        await dispatch(postIndividualCommentSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const getWalletDetails = (data, callback) => {
  const { operatorId } = data;
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}wallet/get-wallet-info?referenceId=${operatorId}`
      )
      .then(async (response) => {
        await dispatch(getWalletDetailsSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

export const postWalletDetails = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(
        `${ApplicationConsts.SOFT_BASE_URL}wallet/add-money-to-wallet`,
        data
      )
      .then(async (response) => {
        await dispatch(postWalletDetailsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const patchTicketStatus = (id, obj, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}ticket-management/update-ticket/${id}`,
        obj
      )
      .then(async (response) => {
        await dispatch(patchTicketStatusSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};
export const patchRegistrationType = (obj, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}user-operations/user-registration-type`,
        obj
      )
      .then(async (response) => {
        await dispatch(patchRegistrationTypeSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const getAircraftLiveLocationDetails = (object, callback) => {
  const { aircraftRegNumber } = object;
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}operator-aircrafts/get-aircraft-live-location/${aircraftRegNumber}`
      )
      .then(async (response) => {
        await dispatch(
          getAircraftLiveLocationDetailsSuccess(response.data.data)
        );
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const postPaymentCheckout = (object, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(
        `${ApplicationConsts.SOFT_BASE_URL}bookings/checkout-payment-wallet`,
        object
      )
      .then(async (response) => {
        await dispatch(postPaymentCheckoutSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};
export const fetchChatRooms = (object, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(`${ApplicationConsts.SOFT_BASE_URL}chat/get-chat-rooms`)
      .then(async (response) => {
        await dispatch(fetchChatRoomsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};
export const getIndividualChatRoomData = (object, callback) => {
  let { chatId, readupdateStatus, limit, chatRoomId } = object;
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}chat/get-chat-room-messages?chatId=${chatId}&limit=${limit}&readupdateStatus=${readupdateStatus}&chatRoomId=${chatRoomId}`
      )
      .then(async (response) => {
        await dispatch(getIndividualChatRoomDataSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const postMessage = (object, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(
        `${ApplicationConsts.SOFT_BASE_URL}chat/send-whatsapp-message`,
        object
      )
      .then(async (response) => {
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const fetchNotificationsLogs = (data, callback) => {
  const { operatorId, typeOfService, notificationModule } = data;
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}user-operations/get-notification-logs?typeOfService=${typeOfService}&referenceId=${operatorId}&skip=0&notificationModule=${notificationModule}`
      )
      .then(async (response) => {
        await dispatch(fetchNotificationsLogsSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

export const patchVerifyPassengers = (
  passenger_id,
  PassengerStatusUpdate,
  callback
) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}passengers/verify-passenger/${passenger_id}`,
        PassengerStatusUpdate
      )
      .then(async (response) => {
        await dispatch(patchVerifyPassengersSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};
export const createChatRoom = (object, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(`${ApplicationConsts.SOFT_BASE_URL}chat/create-chat-room`, object)
      .then(async (response) => {
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const cancelBooking = (booking_id, data, type, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}bookings/cancel-booking/${booking_id}?env=${type}`,
        data
      )
      .then(async (response) => {
        await dispatch(cancelBookingSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error);
      });
  };
};

export const getOperatorSettings = (referenceId, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}settings/operator-settings/${referenceId}`
      )
      .then(async (response) => {
        await dispatch(getOperatorSettingsSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

export const patchOperatorSettings = (reference_id, data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}settings/update-operator-settings/${reference_id}`,
        data
      )
      .then(async (response) => {
        await dispatch(patchOperatorSettingsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const fetchGlobalConstants = (object, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}user-operations/get-global-constants`
      )
      .then(async (response) => {
        await dispatch(fetchGlobalConstantsSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

export const postBookingPushNotifications = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(
        `${ApplicationConsts.SOFT_BASE_URL}bookings/send-operator-notifications`,
        data
      )
      .then(async (response) => {
        await dispatch(postBookingPushNotificationsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

/**  ********************************************
 Function for Customer Quote Request Info
 ********************************************  */
export const getCustomerQuoteRequestInfo = (searchId, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}bookings/get-booking-search-details/${searchId}`
      )
      .then(async (response) => {
        await dispatch(getCustomerQuoteRequestInfoSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};
/**  ********************************************
 Function for Updating Booking Leg Depurture Date and time
 ********************************************  */
export const postUpdateBookingLeg = (booking_id, data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}bookings/update-booking-legs/${booking_id}`,
        data
      )
      .then(async (response) => {
        await dispatch(postUpdateBookingLegSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

/**  ********************************************
 API post Function for Payment remainder
 ********************************************  */
export const postPaymentReminder = (booking_id, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}bookings/payment-reminder/${booking_id}`
      )
      .then(async (response) => {
        await dispatch(postPaymentReminderSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};
/**  ********************************************
 post API Function for Aircraft Availability
 ********************************************  */
export const postAircraftAvailability = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(
        `${ApplicationConsts.SOFT_BASE_URL}bookings/aircraft-availability-status`,
        data
      )
      .then(async (response) => {
        await dispatch(postAircraftAvailabilitySuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

/**  ********************************************
 post API Function for Ferry Checkout Payment
 ********************************************  */
export const postFerryCheckoutPayment = (ferryMasterId, data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(
        `${ApplicationConsts.SOFT_BASE_URL}ferry/ferry-checkout-payment/${ferryMasterId}`,
        data
      )
      .then(async (response) => {
        await dispatch(postFerryCheckoutPaymentSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

/**  ********************************************
 post CCAV Request Handler for Checkout Payment
 ********************************************  */
export const postCCAVRequestHandler = (object, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(
        `${ApplicationConsts.SOFT_BASE_URL}bookings/ccav-request-handler`,
        object
      )
      .then(async (response) => {
        await dispatch(postCCAVRequestHandlerSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response);
      });
  };
};

/**  ********************************************
 API post Function for CCAV Payment transaction details
 ********************************************  */
export const postPaymentCCAVTransactionDetails = (
  order_id,
  booking_id,
  transaction_type,
  callback
) => {
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}bookings/payment-status-details?orderId=${order_id}&bookingId=${booking_id}&transactionType=${transaction_type}`
      )
      .then(async (response) => {
        await dispatch(postCCAVTransactionDetailsSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

/**  ********************************************
 post CCAV Request Handler for Checkout Payment
 ********************************************  */
export const postCCAVRequestWalletHandler = (object, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(`${ApplicationConsts.SOFT_BASE_URL}wallet/add-money-wallet`, object)
      .then(async (response) => {
        await dispatch(postCCAVRequestWalletHandlerSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

/**  ********************************************
 API post Function for Payment remainder
 ********************************************  */
export const getPassengerReminder = (booking_id, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}bookings/passenger-reminder/${booking_id}`
      )
      .then(async (response) => {
        await dispatch(getPassengerReminderSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

/**  ********************************************
 post CCAV Request Handler for Checkout Payment
 ********************************************  */
export const postPaypalRequestHandler = (object, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(
        `${ApplicationConsts.SOFT_BASE_URL}bookings/paypal-request-handler`,
        object
      )
      .then(async (response) => {
        await dispatch(
          postPaypalRequestHandlerSuccess({
            responseData: response.data, // response object
            requestObject: object, // Include the object in the payload
          })
        );
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response);
      });
  };
};


/**  ********************************************
 API post Function for Paypal Payment transaction details
 ********************************************  */
export const postPaymentPaypalTransactionDetails = (
  paymentId,
  token,
  PayerID,
  callback
) => {
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}bookings/paypal-response-handler?paymentId=${paymentId}&token=${token}&PayerID=${PayerID}`
      )
      .then(async (response) => {
        await dispatch(postPaypalTransactionDetailsSuccess(response.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

/**  ********************************************
 API post Function for Paypal Payment transaction details cancel
 ********************************************  */
export const postPaymentPaypalCancelDetails = (token, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(
        `${ApplicationConsts.SOFT_BASE_URL}bookings/paypal-cancel-handler?token=${token}`
      )
      .then(async (response) => {
        await dispatch(postPaypalTransactionDetailsCancel(response.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

export const postTechHaltDetails = (data, callback) => {
  return async (dispatch) => {
    await serviceBase
      .patch(
        `${ApplicationConsts.SOFT_BASE_URL}bookings/update-tech-halt-details`,
        data
      )
      .then(async (response) => {
        await dispatch(postTechHaltDetailsSuccess(response.data));
        await callback(response.data);
      })
      .catch((error) => {
        dispatch(fetchActionfail(error));
        callback(error.response.data);
      });
  };
};

/**  ********************************************
 post paypal Request Handler for Checkout Payment
 ********************************************  */
export const postPaypalRequestWalletHandler = (object, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(
        `${ApplicationConsts.SOFT_BASE_URL}wallet/add-money-wallet-using-paypal`,
        object
      )
      .then(async (response) => {
        await dispatch(
          postPaypalRequestWalletHandlerSuccess(response.data.data)
        );
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

/**  ********************************************
 post Currency converter
 ********************************************  */
export const postCurrencyConverter = (object, callback) => {
  return async (dispatch) => {
    await serviceBase
      .post(
        `${ApplicationConsts.SOFT_BASE_URL}bookings/currencyConverter`,
        object
      )
      .then(async (response) => {
        await dispatch(postCurrencyConverterSuccess(response.data.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};

/**  ********************************************
 API get Function for AWS credentials
 ********************************************  */
export const postAWSCredentialsSuccessDetails = (object, callback) => {
  return async (dispatch) => {
    await serviceBase
      .get(`${ApplicationConsts.SOFT_BASE_URL}user-operations/aws-credentials`)
      .then(async (response) => {
        await dispatch(postAWSCredentialsSuccess(response.data));
        await callback(response.data);
      })
      .catch(async (error) => {
        dispatch(fetchActionfail(error));
        await callback(error.response.data);
      });
  };
};


/**  ********************************************
 API get Function for AWS credentials
 ********************************************  */
 export const setMapApiLoader = (isLoaded, callback) => {
  return async (dispatch) => {
    await dispatch(setMapApiLoaderSuccess(isLoaded));
    // await callback(isLoaded);
  }
};

