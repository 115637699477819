import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "react-datetime/css/react-datetime.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingFallback } from "./reactComponents/utils/utilities";
import {
  useJsApiLoader,
} from "@react-google-maps/api";
import { useDispatch } from "react-redux";
import { setMapApiLoader } from "./reduxToolkit/actions/webAggregatorActions";

const LoginPage = lazy(() => import("./reactComponents/loginPage"));
const LoginVerificationPage = lazy(() =>
  import("./reactComponents/loginVerificationPage")
);
const AfterOTPVerifyChooseType = lazy(() =>
  import("./reactComponents/afterOTPVerifyChooseType")
);
const DashboardRoutes = lazy(() => import("./routes/dashboardRoutes"));

/**  ********************************************
 App Component
 ********************************************  */
const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const accessToken = localStorage.getItem("accessToken");
  const mobileNumber = localStorage.getItem("mobileNumber");

  const placesLibrary = ["places"];
  const { REACT_APP_googleKey } = process.env;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: REACT_APP_googleKey,
    libraries: placesLibrary,
  });

  useEffect(() => {
   
    dispatch(setMapApiLoader(isLoaded))
    if (accessToken && location.pathname === "/") {
      navigate("/dashboard");
    } else if (!accessToken && !mobileNumber && location.pathname !== "/") {
      navigate("/");
    }
  }, [accessToken, location.pathname, isLoaded]);



  return (
    <>
      {/* <Router> */}
      <Routes>
        <Route exact path="/" element={<Suspense fallback={<LoadingFallback />}>
        <LoginPage />
          </Suspense>}></Route>
        {/* <Route exact path="/" element={<LoginPage />}></Route> */}
        {/* )} */}

        <Route
          exact
          path="/otpverificationpage"
          element={<Suspense fallback={<LoadingFallback />}>
          <LoginVerificationPage />
        </Suspense>}
        ></Route>
        <Route
          exact
          path="/chooseRegistrationType"
          element={<Suspense fallback={<LoadingFallback />}>
          <AfterOTPVerifyChooseType />
        </Suspense>}
        ></Route>

        <Route path="/*" element={<DashboardRoutes />}></Route>
      </Routes>
      {/* </Router> */}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default App;
