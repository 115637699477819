import { actionTypes } from "../constants/actionTypes";

const initialState = {
  registrationDetails: [],
  OTPVerify: [],
  operatorOnboardData: [],
  aggregatorSoftStatistics: [],
  dgcaDataOperatorsData: [],
  individualOperatorDetails: [],
  individualAgentDetails: [],
  individualOperatorAircraftsDetails: [],
  individualOperatorDgcaAircraftsDetails: [],
  passengersDetails: [],
  operatorsDetails: [],
  guestDetails: [],
  aircraftsDetails: [],
  allDGCAOperatorDetails: [],
  aggregatorStaffDetails: [],
  onboardedAgentsData: [],
  NSOPoperatorsData: [],
  addAggregatorStaffDetails: [],
  deleteAggregatorStaffDetails: [],
  editAggregatorStaffDetails: [],
  operatorQuotes: [],
  agentStatusUpdate: [],
  operatorStatusUpdate: [],
  operatorEnquires: [],
  bookingsDetails: [],
  searchAircraftsDetails: [],
  searchAirportDetails: [],
  allPaymentDetails: [],
  paymentVerificationStatus: [],
  getQuoteStatus: [],
  aircraftVerificationStatus: [],
  postFareDetails: [],
  patchRejectBookings: [],
  individualTickets: [],
  ticketDetails: [],
  postAssignCrew: [],
  postGoLive: [],
  postComment: [],
  RegistrationType: [],
  paymentSuccess: [],
  chatRooms: [],
  chatRoomData: [],
  BookingAddPassengers: [],
  cancelBooking: [],
  IntrestInFerry: [],
  bookingPushNotification: [],
  patchOperatorSettingsDetails: [],
  updatedBookingLeg: [],
  paymentReminder: [],
  aircraftAvailability: [],
  CCAVRequestHandlerDetails: [],
  CCAVTransactionDetails: {},
  PaypalRequestObject: {},
  techHaltDetails: {},
  longLeaseStatistics: {},
  individualLongLeaseDetails: {},
  postLongLeaseFareDetails: {},
  isMapApiLoaded: false,
};

export const registrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MOBILE_EMAIL_VERIFICATION:
      return {
        ...state,
        registrationDetails: action.fetchAggregatorLoginDetailsSuccess,
      };
    case actionTypes.OTP_VERIFY:
      return {
        ...state,
        OTPVerify: action.verifyOTPSuccess,
      };
    case actionTypes.GET_SOFT_STATISTICS:
      return {
        ...state,
        aggregatorSoftStatistics: action.fetchAggregatorSoftStatisticsSuccess,
      };
    case actionTypes.FETCH_OPERATOR_DGCA_DETAILS:
      return {
        ...state,
        dgcaDataOperatorsData: action.getDGCAOperatorsDataSuccess,
      };
    case actionTypes.FETCH_INDIVIDUAL_OPERATOR_DETAILS:
      return {
        ...state,
        individualOperatorDetails: action.fetchIndividualOperatorDetailsSuccess,
      };
    case actionTypes.FETCH_INDIVIDUAL_AGENT_DETAILS:
      return {
        ...state,
        individualAgentDetails: action.fetchIndividualAgentDetailsSuccess,
      };
    case actionTypes.FETCH_INDIVIDUAL_OPERATOR_AIRCRAFTS_DETAILS:
      return {
        ...state,
        individualOperatorAircraftsDetails:
          action.fetchOperatorAircraftDetailsSuccess,
      };
    case actionTypes.FETCH_INDIVIDUAL_OPERATOR_DGCA_AIRCRAFTS_DETAILS:
      return {
        ...state,
        individualOperatorDgcaAircraftsDetails:
          action.fetchOperatorDgcaAircraftDetailsSuccess,
      };
    case actionTypes.FETCH_PASSANGERS_DETAILS:
      return {
        ...state,
        passengersDetails: action.fetchPassengersDataSuccess,
      };
    case actionTypes.FETCH_OPERATOR_DETAILS:
      return {
        ...state,
        operatorsDetails: action.fetchAllOperatorsDataSuccess,
      };
    case actionTypes.FETCH_GUEST_DETAILS:
      return {
        ...state,
        guestDetails: action.fetchGuestDetailsSuccess,
      };
    case actionTypes.FETCH_AIRCRAFTS_DETAILS:
      return {
        ...state,
        aircraftsDetails: action.fetchAircraftsDataSuccess,
      };
    case actionTypes.FETCH_ALL_DGCA_OPERATORS_DETAILS:
      return {
        ...state,
        allDGCAOperatorDetails: action.fetchAllDGCAOperatorsDataSuccess,
      };
    case actionTypes.SET_ADD_OPERATOR_DETAILS:
      return {
        ...state,
        AddedOperatorDetails: action.setAddOperatorDetailsSuccess,
      };
    case actionTypes.FETCH_ONBOARDED_STAFF_DETAILS:
      return {
        ...state,
        aggregatorStaffDetails: action.fetchAggregatorStaffDataSuccess,
      };
    case actionTypes.FETCH_ONBOARDED_AGENTS_DATA:
      return {
        ...state,
        onboardedAgentsData: action.fetchAllOnboarededAgentsDataSuccess,
      };
    case actionTypes.FETCH_NSOP_OPERATOR_DETAILS:
      return {
        ...state,
        NSOPoperatorsData: action.fetchNSOPoperatorsDataSuccess,
      };
    case actionTypes.SET_ADD_AGGREGATOR_STAFF_DETAILS:
      return {
        ...state,
        addAggregatorStaffDetails: action.AddAggregatorStaffDataSuccess,
      };
    case actionTypes.SET_DELETE_AGGREGATOR_STAFF_DETAILS:
      return {
        ...state,
        deleteAggregatorStaffDetails: action.deleteAggregatorstaffSuccess,
      };
    case actionTypes.SET_EDIT_AGGREGATOR_STAFF_DETAILS:
      return {
        ...state,
        editAggregatorStaffDetails: action.editAggregatorstaffDetailsSuccess,
      };
    case actionTypes.SET_ADD_AGENT_DETAILS:
      return {
        ...state,
        AddedAgentDetails: action.setAddAgentDetailsSuccess,
      };
    case actionTypes.PATCH_AGENTS_STATUS:
      return {
        ...state,
        agentStatusUpdate: action.setAgentStatusUpdateSuccess,
      };
    case actionTypes.PATCH_OPERATOR_STATUS:
      return {
        ...state,
        operatorStatusUpdate: action.setOperatorStatusUpdateSuccess,
      };
    case actionTypes.FETCH_INDIVIDUAL_AIRCRAFT_DETAILS:
      return {
        ...state,
        individualAircraftDetails: action.fetchIndividualAgentDetailsSuccess,
      };
    case actionTypes.GET_OPERATOR_ENQUIRIES:
      return {
        ...state,
        operatorEnquires: action.fetchOperatorEnquiresSuccess,
      };
    case actionTypes.GET_LONG_LEASE_ENQUIRIES:
      console.log(action.fetchLongLeaseEnquiresSuccess.data.statistics);
      return {
        ...state,
        operatorEnquires: action.fetchLongLeaseEnquiresSuccess,
        longLeaseStatistics:
          action.fetchLongLeaseEnquiresSuccess.data.statistics,
      };
    case actionTypes.GET_INDIVIDUAL_BOOKINGS:
      return {
        ...state,
        bookingsDetails: action.fetchIndividualBookingsSuccess,
      };
    case actionTypes.PATCH_AIRCRAFT_STATUS:
      return {
        ...state,
        aircraftStatusUpdate: action.setAircraftStatusUpdateSuccess,
      };
    case actionTypes.FETCH_ONBOARDED_AIRCRAFTS_DETAILS:
      return {
        ...state,
        onboardedAircraftDetails: action.fetchOnboardedAircraftsDetailsSuccess,
      };
    case actionTypes.FETCH_SEARCH_AIRCRAFTS_DETAILS:
      return {
        ...state,
        searchAircraftsDetails: action.fetchSearchAircrftsDetailsSuccess,
      };
    case actionTypes.FETCH_SEARCH_LONG_LEASE_AIRCRAFTS_DETAILS:
      return {
        ...state,
        searchAircraftsDetails:
          action.fetchSearchLongLeaseAircraftsDetailsSuccess,
      };
    case actionTypes.FETCH_SEARCH_AIRPORTS_DETAILS:
      return {
        ...state,
        searchAirportDetails: action.fetchSearchAirportsDetailsSuccess,
      };
    case actionTypes.FETCH_ALL_PAYMENT_DETAILS:
      return {
        ...state,
        allPaymentDetails: action.fetchAllPaymentDetailsSuccess,
      };
    case actionTypes.PATCH_OPERATOR_DOCUMENTS:
      return {
        ...state,
        operatorDocumentsUpdate: action.patchOperatorDocumentsSuccess,
      };
    case actionTypes.PATCH_BOARDOFDIRECTORS:
      return {
        ...state,
        boardOfDirectorsUpdate: action.patchBoardOfDirectorsSuccess,
      };
    case actionTypes.PATCH_BANKDETAILS:
      return {
        ...state,
        bankDetailsUpdate: action.patchBankDetailsSuccess,
      };
    case actionTypes.PATCH_OPERATOR_DETAILS:
      return {
        ...state,
        operatorDetailsUpdate: action.patchOperatorDetailsSuccess,
      };
    case actionTypes.PATCH_AGENT_DOCUMENTS:
      return {
        ...state,
        agentDocumentsUpdate: action.patchAgentDocumentsSuccess,
      };
    case actionTypes.PATCH_AGENT_DETAILS:
      return {
        ...state,
        agentDetailsUpdate: action.patchAgentDetailsSuccess,
      };
    case actionTypes.FETCH_SOFT_PAYMENTS:
      return {
        ...state,
        softPaymentDetails: action.fetchSoftPaymentDetailsSuccess,
      };
    case actionTypes.FETCH_FERRY_AND_IDEAL_DAYS:
      return {
        ...state,
        softFerryAndIdealDaysDetails:
          action.fetchFerryAndIdealDaysDetailsSuccess,
      };
    case actionTypes.POST_AIRCRAFT_DETAILS:
      return {
        ...state,
        addAircraftsDetails: action.postAircraftsDetailsSuccess,
      };
    case actionTypes.UPDATE_PAYMENT_VERIFICATION_STATUS:
      return {
        ...state,
        paymentVerificationStatus:
          action.setPaymentVerificationStatusUpdateSuccess,
      };
    case actionTypes.PATCH_AIRCRAFT_DETAILS:
      return {
        ...state,
        aircraftDetailsUpdate: action.patchAircraftDetailsSuccess,
      };
    case actionTypes.POST_ADD_PASSENGER_DETAILS:
      return {
        ...state,
        addPassengerDetails: action.postAddPassengerDetailsSuccess,
      };
    case actionTypes.POST_ADD_PASSENGER_GROUP_DETAILS:
      return {
        ...state,
        addPassengerGroupDetails: action.postAddPassengerGroupDetailsSuccess,
      };
    case actionTypes.GET_PASSENGER_GROUP_DETAILS:
      return {
        ...state,
        getPassengerGroupDetails: action.getPassengerGroupDetailsSuccess,
      };
    case actionTypes.PATCH_PASSENGER_DETAILS:
      return {
        ...state,
        updatePassengerDetails: action.patchPassengerDetailsSuccess,
      };
    case actionTypes.PATCH_PASSENGER_GROUP_DETAILS:
      return {
        ...state,
        updatePassengerGroupDetails: action.patchPassengerGroupDetailsSuccess,
      };
    case actionTypes.DELETE_PASSENGER_DETAILS:
      return {
        ...state,
        deletePassengerDetails: action.deletePassengerDetailsSuccess,
      };
    case actionTypes.GET_CREW_DETAILS:
      return {
        ...state,
        getCrewDetails: action.getCrewDetailsSuccess,
      };
    case actionTypes.POST_CREW_DETAILS:
      return {
        ...state,
        addCrewDetails: action.postCrewDetailsSuccess,
      };
    case actionTypes.PATCH_DELETE_CREW_DETAILS:
      return {
        ...state,
        updateDeletedCrewDetails: action.patchDeleteCrewDetailsSuccess,
      };
    case actionTypes.POST_GET_QUOTE:
      return {
        ...state,
        getQuoteStatus: action.postGetQuoteSuccess,
      };
    case actionTypes.POST_GET_LONG_LEASE_QUOTE:
      return {
        ...state,
        getQuoteStatus: action.postLongLeaseGetQuoteSuccess,
      };
    case actionTypes.PATCH_AIRCRAFT_VERIFICATION:
      return {
        ...state,
        aircraftVerificationStatus: action.patchAircraftStatusUpdateSuccess,
      };
    case actionTypes.PATCH_CREW_DETAILS:
      return {
        ...state,
        updateCrewDetails: action.patchCrewDetailsSuccess,
      };
    case actionTypes.POST_SEND_ENQUIRY_FARE_DETAILS:
      return {
        ...state,
        postFareDetails: action.postSendEnquiryFareDetailsSuccess,
      };
    case actionTypes.POST_SEND_LONG_LEASE_ENQUIRY_FARE_DETAILS:
      return {
        ...state,
        postLongLeaseFareDetails:
          action.postSendLongLeaseEnquiryFareDetailsSuccess,
      };
    case actionTypes.PATCH_REJECT_BOOKING:
      return {
        ...state,
        patchRejectBookings: action.patchRejectBookingSuccess,
      };
    case actionTypes.PATCH_REJECT_LONG_LEASE:
      return {
        ...state,
        patchRejectBookings: action.patchRejectLongLeaseSuccess,
      };

    case actionTypes.GET_INDIVIDUAL_TICKETS:
      return {
        ...state,
        individualTickets: action.fetchIndividualAssignedTicketsDetailsSuccess,
      };
    case actionTypes.POST_ASSIGN_CREW:
      return {
        ...state,
        postAssignCrew: action.postAssignCrewSuccess,
      };
    case actionTypes.POST_GO_LIVE:
      return {
        ...state,
        postGoLive: action.postGoLiveSuccess,
      };
    case actionTypes.GET_INDIVIDUAL_TICKET_DETAILS:
      return {
        ...state,
        ticketDetails: action.fetchIndividualAssignedTicketsDetailsSuccess,
      };
    case actionTypes.POST_INDIVIDUAL_TICKET_COMMENT:
      return {
        ...state,
        ticketDetails: action.postIndividualCommentSuccess,
      };
    case actionTypes.GET_WALLET_DETAILS:
      return {
        ...state,
        getWalletDetails: action.getWalletDetailsSuccess,
      };
    case actionTypes.POST_WALLET_DETAILS:
      return {
        ...state,
        postWalletDetails: action.postWalletDetailsSuccess,
      };
    case actionTypes.PATCH_TICKET_STATUS:
      return {
        ...state,
        ticketStatusUpdated: action.patchTicketStatusSuccess,
      };
    case actionTypes.PATCH_REGISTRATION_TYPE:
      return {
        ...state,
        RegistrationType: action.patchRegistrationTypeSuccess,
      };
    case actionTypes.GET_AIRCRAFT_LIVE_LOCATION:
      return {
        ...state,
        ticketStatusUpdated: action.getAircraftLiveLocationDetailsSuccess,
      };
    case actionTypes.POST_PAYMENT_CHECKOUT:
      return {
        ...state,
        paymentSuccess: action.postPaymentCheckoutSuccess,
      };
    case actionTypes.GET_CHAT_ROOM_DATA:
      return {
        ...state,
        chatRoomData: action.getIndividualChatRoomData,
      };
    case actionTypes.GET_CHAT_ROOMS:
      return {
        ...state,
        chatRooms: action.fetchChatRoomsSuccess,
      };
    case actionTypes.GET_NOTIFICATIONS_LOG:
      return {
        ...state,
        notificationsLogsDetails: action.fetchNotificationsLogsSuccess,
      };
    case actionTypes.POST_BOOKING_ADD_PASSENGERS:
      return {
        ...state,
        BookingAddPassengers: action.patchBookingAddPassengerDetailsSuccess,
      };
    case actionTypes.PATCH_VERIFY_PASSENGERS:
      return {
        ...state,
        patchVerifyPassengersDetails: action.patchVerifyPassengersSuccess,
      };
    case actionTypes.PATCH_CANCEL_BOOKING:
      return {
        ...state,
        cancelBooking: action.cancelBookingSuccess,
      };
    case actionTypes.GET_OPERATOR_SETTINGS:
      return {
        ...state,
        getOperatorSettingsDetails: action.getOperatorSettingsSuccess,
      };
    case actionTypes.PATCH_OPERATOR_SETTINGS:
      return {
        ...state,
        patchOperatorSettingsDetails: action.patchOperatorSettingsSuccess,
      };
    case actionTypes.FETCH_GLOBAL_CONSTANTS:
      return {
        ...state,
        globalConstantsDetails: action.fetchGlobalConstantsSuccess,
      };
    case actionTypes.PATCH_INTRESTED_FERRY:
      return {
        ...state,
        IntrestInFerry: action.PatchIntrestedInFerrySuccess,
      };
    case actionTypes.BOOKING_PUSH_NOTIFICATIONS:
      return {
        ...state,
        bookingPushNotification: action.postBookingPushNotificationsSuccess,
      };
    case actionTypes.CUSTOMER_QUOTE_REQUEST_INFO:
      return {
        ...state,
        getCustomerQuoteRequestInfoDetails:
          action.getCustomerQuoteRequestInfoSuccess,
      };
    case actionTypes.UPDATE_BOOKING_LEG:
      return {
        ...state,
        updatedBookingLeg: action.postUpdateBookingLegSuccess,
      };
    case actionTypes.POST_PAYMENT_REMINDER:
      return {
        ...state,
        paymentReminder: action.postPaymentReminderSuccess,
      };
    case actionTypes.POST_AIRCRAFT_AVAILIBILITY:
      return {
        ...state,
        aircraftAvailability: action.postAircraftAvailabilitySuccess,
      };
    case actionTypes.GET_LONG_LEASE_STATISTICS_DETAILS:
      return {
        ...state,
        longLeaseStatistics: action.fetchLongLeaseStatisticsSuccess,
      };
    case actionTypes.FERRY_CHECKOUT_PAYMENT:
      return {
        ...state,
        ferryCheckoutPaymentDetails: action.postFerryCheckoutPaymentSuccess,
      };
    case actionTypes.CCAV_REQUEST_HANDLER:
      return {
        ...state,
        CCAVRequestHandlerDetails: action.postCCAVRequestHandlerSuccess,
      };
    case actionTypes.CCAV_TRANSACTION_DETAILS:
      return {
        ...state,
        CCAVTransactionDetails: action.postCCAVTransactionDetailsSuccess,
      };
    case actionTypes.CCAV_REQUEST_WALLET_HANDLER:
      return {
        ...state,
        CCAVRequestWalletHandlerDetails:
          action.postCCAVRequestWalletHandlerSuccess,
      };
    case actionTypes.GET_PASSENGER_REMINDER:
      return {
        ...state,
        passengerReminderDetails: action.getPassengerReminderSuccess,
      };
    case actionTypes.PAYPAL_REQUEST_HANDLER:
      return {
        ...state,
        PaypalRequestHandlerDetails:
          action.postPaypalRequestHandlerSuccess?.responseData,
        PaypalRequestObject:
          action.postPaypalRequestHandlerSuccess?.requestObject,
      };
    case actionTypes.PAYPAL_RESPONSE_HANDLER:
      return {
        ...state,
        PaypalTransactionDetails: action.postPaypalTransactionDetailsSuccess,
      };
    case actionTypes.PAYPAL_CANCEL_HANDLER:
      return {
        ...state,
        PaypalCancelTransactionDetails:
          action.postPaypalTransactionDetailsCancel,
      };
    case actionTypes.POST_TECH_HALT_DETAILS:
      return {
        ...state,
        techHaltDetails: action.postTechHaltDetailsSuccess,
      };
    case actionTypes.PAYPAL_REQUEST_WALLET_HANDLER:
      return {
        ...state,
        PaypalRequestWalletHandlerDetails:
          action.postPaypalRequestWalletHandlerSuccess,
      };
    case actionTypes.CURRENCY_CONVERTER:
      return {
        ...state,
        currencyConverterDetails: action.postCurrencyConverterSuccess,
      };
    case actionTypes.AWS_CREDENTIALS:
      return {
        ...state,
        AWSCredentialDetails: action.postAWSCredentialsSuccess,
      };
    case actionTypes.GET_INDIVIDUAL_LONG_LEASE_DETAILS:
      return {
        ...state,
        individualLongLeaseDetails:
          action.fetchIndividualLongLeaseDetailsSuccess,
      };
    case actionTypes.API_LOADER:
      return {
        ...state,
        isMapApiLoaded: action.setMapApiLoaderSuccess,
      };
    default:
      return state;
  }
};
